import {StyleSheet, TouchableOpacity, View} from 'react-native';
import Text from '@/components/basic/text';
// import {Shadow} from 'react-native-shadow-2';
import React from 'react';
import theme from '@/style';
import {COLORS} from '../constant';
import {BasicObject} from '@/types';

export interface BetsItemProps {
  type: 'A' | 'B' | 'C' | 'D';
  onItemPress?: (v?: View | null) => void;
  onDisabledPress?: () => void;
  isDigit?: boolean;
  digit?: string;
  rate?: string;
  betsType?: string;
  disabled?: boolean;
  checked?: boolean;
  isPrimary?: boolean;
}

// 4px 4px 8px rgba(0, 0, 0, 0.25)

const BetsItem = ({
  isDigit = false,
  digit,
  rate,
  disabled,
  type = 'A',
  isPrimary = false,
  checked = false,
  onDisabledPress,
  onItemPress,
}: BetsItemProps) => {
  const borderColor = React.useMemo(() => {
    if (isPrimary) {
      return checked ? theme.basicColor.primary : 'var(--game-line)';
    } else {
      return COLORS[type];
    }
  }, [isPrimary, checked, type]);

  const viewRef = React.useRef(null);

  return (
    <TouchableOpacity
      onPress={() => {
        if (disabled) {
          onDisabledPress?.();
        } else {
          onItemPress?.(viewRef.current);
        }
      }}
      ref={viewRef}
      activeOpacity={disabled ? 0.3 : 0.8}
      style={[
        theme.flex.center,
        isDigit ? styles.circle : styles.block,
        {borderColor},
        {
          backgroundColor: checked
            ? isPrimary
              ? theme.basicColor.primary
              : COLORS[type]
            : 'var(--button-s)',
        },
        // eslint-disable-next-line react-native/no-inline-styles
        disabled && {opacity: 0.3},
        !checked &&
          ({
            boxShadow:
              '-4px -4px 8px 0px rgba(255, 255, 255, 0.15), 4px 4px 8px 0px rgba(0, 0, 0, 0.25)',
          } as BasicObject),
      ]}>
      {isDigit ? (
        <Text color={'var(--T1)'} fontFamily="fontInterBold" size="large">
          {digit}
        </Text>
      ) : (
        <>
          <Text fontFamily="fontInterBold" size="medium" color={'var(--T1)'}>
            {digit}
          </Text>
          <Text color={'var(--T2-dark)'}>{rate}</Text>
        </>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  circle: {
    width: 40,
    height: 40,
    borderWidth: 2,
    borderRadius: 20,
  },
  block: {
    flex: 1,
    // width: '100%',
    height: 60,
    borderWidth: 2,
    borderRadius: 8,
  },
});

export default React.memo(BetsItem);
