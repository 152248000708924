import {StyleSheet, View} from 'react-native';
import Text from '@/components/basic/text';
import React from 'react';
import {COLORS, INDEX_TO_O, RESULT_INDEX} from '../constant';
import theme from '@/style';
import DigitBall from '../component/digit-ball';
import {LotteryMode} from '../mix-lottery-service';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import LazyImage from '@/components/basic/image';
import {useTranslation} from 'react-i18next';
import {ArrowRightIcon} from '../svg.variable';
import {handleToLive} from '@/utils';

export interface GameResultProps {
  resultList: string[];
  modeId?: `${LotteryMode}`;
  isLive?: boolean;
}

const GameResult = ({
  resultList = [],
  modeId,
  isLive = false,
}: GameResultProps) => {
  const {calcActualSize} = useScreenSize();
  const {t} = useTranslation();
  const disabledTypes = React.useMemo(() => {
    switch (modeId) {
      case '3':
      case '6':
        return ['A'];
      case '4':
      case '7':
      case '8':
        return ['A', 'B'];
      case '9':
        return ['A', 'B', 'C'];
      default:
        return [];
    }
  }, [modeId]);

  const styles = StyleSheet.create({
    officialIconContainer: {
      width: 18,
      height: 16,
    },
  });

  return (
    <View style={[{gap: theme.paddingSize.xxs}]}>
      <View style={[theme.flex.row, theme.flex.between]}>
        <Text color={'#5F6975'}>Previous Result：</Text>
        {isLive && (
          <NativeTouchableOpacity
            style={[
              theme.flex.row,
              {gap: theme.paddingSize.xxs},
              theme.borderRadius.xs,
              theme.padding.rightxxs,
              theme.flex.center,
              {backgroundColor: 'white'},
            ]}
            onPress={handleToLive}>
            <View style={[styles.officialIconContainer, theme.flex.center]}>
              <LazyImage
                occupancy="#0000"
                width={18}
                height={18}
                imageUrl={require('@components/assets/icons/mix-lotto/live.gif')}
              />
            </View>
            <Text color={'#31373D'} fontSize={9}>
              {t('mix-lotto.label.official')}
            </Text>
            <ArrowRightIcon
              width={8}
              height={8}
              stroke={theme.fontColor.secAccent}
            />
          </NativeTouchableOpacity>
        )}
      </View>
      <View
        style={[
          theme.flex.row,
          theme.flex.centerByRow,
          theme.flex.end,
          theme.padding.lrxxs,
          // eslint-disable-next-line react-native/no-inline-styles
          {backgroundColor: '#D5CECE'},
        ]}>
        <View style={[theme.flex.row, {gap: theme.paddingSize.xxs}]}>
          {RESULT_INDEX.filter(item => item !== 'UNUSED').map(pie => (
            <View
              style={[theme.flex.center, {width: calcActualSize(20)}]}
              key={pie}>
              <Text
                fontFamily="fontInterBold"
                color={COLORS[pie]}
                key={pie}
                // eslint-disable-next-line react-native/no-inline-styles
                style={{opacity: disabledTypes.includes(pie) ? 0.3 : 1}}>
                {pie}
              </Text>
            </View>
          ))}
        </View>
      </View>
      {resultList.map((item, i) => (
        <View
          key={i}
          style={[
            theme.flex.row,
            theme.flex.centerByCol,
            theme.flex.between,
            theme.padding.rightxxs,
          ]}>
          <Text color={'#31373D'} fontFamily="fontInterBold">
            {INDEX_TO_O[i + 1]} Prize：
          </Text>
          <View
            style={[
              theme.flex.row,
              theme.flex.centerByCol,
              {gap: theme.paddingSize.xxs},
            ]}>
            {item.split('').map((pie, _ii) => (
              <DigitBall
                key={_ii}
                disabled={
                  RESULT_INDEX.slice(-item.split('').length)[_ii] ===
                    'UNUSED' ||
                  disabledTypes.includes(
                    RESULT_INDEX.slice(-item.split('').length)[_ii],
                  )
                }
                digit={pie}
                type={RESULT_INDEX.slice(-item.split('').length)[_ii]}
              />
            ))}
          </View>
        </View>
      ))}
    </View>
  );
};

export default GameResult;
