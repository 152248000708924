import {BasicObject} from '@/types';
import {LotteryMode, LotteryModeName} from './mix-lottery-service';

export const COLORS = {
  A: '#D57300',
  B: '#0087D4',
  C: '#D50000',
  D: '#67A519',
  UNUSED: '#5F6975',
} as BasicObject;

export const COLORS_BORDER = {
  A: 'rgb(160,95,32)',
  B: 'rgb(44,108,165)',
  C: 'rgb(156,30,20)',
  D: 'rgb(91,125,43)',
  UNUSED: '#5F6975',
} as BasicObject;

export const COLORS_LIGHT_BORDER = {
  A: 'rgb(192,121,47)',
  B: 'rgb(55,130,198)',
  C: 'rgb(174,39,30)',
  D: 'rgb(115,158,55)',
  UNUSED: '#5F6975',
} as BasicObject;

export const COLORS_LIGHT = {
  A: '#FF9416',
  B: '#00A2FF',
  C: '#F32C2C',
  D: '#7FC922',
  UNUSED: '#5F6975',
} as BasicObject;

export const INDEX_TO_O = {
  1: '1st',
  2: '2nd',
  3: '3rd',
} as BasicObject;

export const BETS_BG = {
  A: 'var(--button-s)',
  B: '#484E56',
  C: 'var(--button-s)',
  D: '#484E56',
} as BasicObject;

export const CART_ACTION_COLOR = {
  20: '#1BB458',
  50: '#228BA3',
  100: '#2047AC',
  500: '#A538B7',
  MAX: '#E75300',
} as BasicObject;

export const CART_ACTION_SHADOW_COLOR = {
  20: '#10793A',
  50: '#19697B',
  100: '#17347D',
  500: '#662471',
  MAX: '#2A2E34',
} as BasicObject;

export const MAIN_KEY = {
  Big: '5,6,7,8,9',
  Small: '0,1,2,3,4',
  Odd: '1,3,5,7,9',
  Even: '0,2,4,6,8',
  Fish: '1,2,3',
  Prawn: '4,5,6',
  Crab: '7,8,9',
} as BasicObject;

export const NORMAL_NUM = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

export const ACTIONS = ['20', '50', '100', '500', 'MAX'];

export const NORMAL_TYPE = ['Odd', 'Even', 'Big', 'Small'];

export const RESULT_INDEX = ['UNUSED', 'UNUSED', 'A', 'B', 'C', 'D'] as [
  'UNUSED',
  'UNUSED',
  'A',
  'B',
  'C',
  'D',
];

export const DEFAULT_TAB = [
  'Two Side',
  '4D',
  '3D',
  '2D',
  '4X',
  '3X',
  '2X',
  '1 Digit',
  'FishPrawnCrab',
].map(modeName => ({
  modeName,
  modeID: LotteryMode[modeName as LotteryModeName],
}));
