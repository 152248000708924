import {TouchableOpacity, View} from 'react-native';
import Text from '@/components/basic/text';
import Button from '@/components/basic/button';
import React from 'react';
import theme from '@/style';
import Dustbin from '@/components/svgs/basic/dustbin';
import {useTranslation} from 'react-i18next';

export interface BetsActionsProps {
  onAdd?: (view?: View | null) => void;
  counts?: number;
  onDel?: () => void;
}

const BetsActions = ({counts = 0, onAdd, onDel}: BetsActionsProps) => {
  const {t} = useTranslation();
  const viewRef = React.useRef<View>(null);
  return (
    <View
      style={[
        theme.flex.row,
        theme.flex.between,
        theme.flex.centerByCol,
        theme.padding.l,
        theme.margin.btml,
        // eslint-disable-next-line react-native/no-inline-styles
        {backgroundColor: 'var(--card)'},
      ]}>
      <View
        style={[
          theme.flex.row,
          theme.flex.centerByCol,
          {gap: theme.paddingSize.s},
        ]}>
        <Text fontWeight="500">Bet Slip Counts:</Text>
        <Text fontFamily="fontInterBold" color={'var(--T1)'}>
          {counts}
        </Text>
      </View>
      <View style={[theme.flex.row, theme.flex.centerByCol]}>
        <TouchableOpacity
          onPress={() => onDel?.()}
          disabled={counts === 0}
          activeOpacity={0.8}
          style={[
            {marginRight: theme.paddingSize.m * 2},
            // eslint-disable-next-line react-native/no-inline-styles
            counts === 0 && {opacity: 0.3},
          ]}>
          <Dustbin color={'var(--T2)'} />
        </TouchableOpacity>
        <View collapsable={false} ref={viewRef}>
          <Button
            disabled={counts === 0}
            onPress={() => onAdd?.(viewRef.current)}
            // eslint-disable-next-line react-native/no-inline-styles
            disabledStyle={[{opacity: 0.3}, theme.background.primary]}
            type="primary"
            titleFontFamily="fontInterBold"
            title={t('mix-lotto.label.addToBetSlip')}
          />
        </View>
      </View>
    </View>
  );
};

export default BetsActions;
