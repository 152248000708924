import Dustbin from '@/components/svgs/basic/dustbin';
import {TouchableOpacity, View, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import React from 'react';
import {ACTIONS, COLORS, INDEX_TO_O} from '../constant';
import CartActionButton from '../component/cart-action-btn';
import theme from '@/style';
import DigitBall from '../component/digit-ball';
import {TextInput} from '@/components/basic/input-field';
import {CartListItem, LotteryMode} from '../mix-lottery-service';
import Tag from '../component/tag';
import globalStore from '@/services/global.state';
import {useTranslation} from 'react-i18next';
import NP from '@/components/utils/number-precision';
export interface CartItemProps {
  onDel?: () => void;
  onAction?: () => void;
  changeAmount?: (amount: string) => void;
}

const CartItem = ({
  modeID,
  odds,
  label,
  value,
  min = 20,
  max = 30,
  amount,
  type,
  selectPrize,
  onDel,
  changeAmount,
}: CartItemProps & CartListItem) => {
  const {t} = useTranslation();
  const [focus, setFocus] = React.useState(false);
  return (
    <View
      style={[
        theme.padding.l,
        theme.borderRadius.xl,
        theme.margin.btms,
        // eslint-disable-next-line react-native/no-inline-styles
        {backgroundColor: 'var(--button-s)'},
      ]}>
      <View
        style={[theme.flex.row, theme.flex.centerByCol, theme.flex.between]}>
        <View style={[theme.flex.row, theme.flex.centerByCol]}>
          <Text fontFamily="fontInterBold">
            {INDEX_TO_O[selectPrize]} Priz | {LotteryMode[modeID]}:
          </Text>
          {modeID === LotteryMode['Two Side'] ||
          modeID === LotteryMode.FishPrawnCrab ? (
            <Tag
              label={label}
              backgroundColor={COLORS[type]}
              style={[theme.margin.lrxxs]}
            />
          ) : (
            <View
              style={[
                theme.flex.row,
                theme.flex.centerByCol,
                theme.margin.lrxxs,
                {gap: theme.paddingSize.xxs},
              ]}>
              {value.split('').map((item, _i) => (
                <DigitBall
                  key={_i}
                  digit={item}
                  type={type.split('')[_i] as 'A' | 'B' | 'C' | 'D'}
                />
              ))}
            </View>
          )}

          <Text fontWeight="500">@</Text>
          <Text fontWeight="500" color={'#F15802'}>
            {odds}
          </Text>
        </View>
        <TouchableOpacity activeOpacity={0.8} onPress={onDel}>
          <Dustbin />
        </TouchableOpacity>
      </View>
      <View
        style={[
          theme.flex.row,
          theme.flex.centerByCol,
          theme.margin.tbl,
          {gap: theme.paddingSize.l},
        ]}>
        <TextInput
          value={`${amount}`}
          onValueChange={v => {
            const regex = /^[0-9\b]+$/;
            if (regex.test(v) || !v) {
              changeAmount?.(v);
            }
          }}
          containerStyle={[
            styles.inputContainer,
            // eslint-disable-next-line react-native/no-inline-styles
            {
              borderColor: focus
                ? theme.basicColor.primary
                : 'var(--game-line)',
            },
          ]}
          placeholder="Betting amount"
          hasMax={false}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
            if (Number(amount) < min) {
              changeAmount?.(`${min}`);
              globalStore.globalWaringTotal(
                t('mix-lotto.tip.minAmount', {num: min}),
              );
            }
            if (Number(amount) > max) {
              changeAmount?.(`${max}`);
              globalStore.globalWaringTotal(
                t('mix-lotto.tip.betMax', {num: max}),
              );
            }
          }}
          // eslint-disable-next-line react-native/no-inline-styles
          textInputStyle={{fontWeight: '700'}}
        />
        <View style={[theme.flex.flex1]}>
          <Text fontWeight="500">Min Bet: {min}</Text>
          <Text fontWeight="500">Max Bet: {max}</Text>
        </View>
      </View>
      <Text accent fontWeight="500">
        {t('mix-lotto.label.est')}: {NP.times(Number(amount), Number(odds))}
      </Text>
      <View
        style={[theme.flex.row, theme.margin.tops, {gap: theme.paddingSize.s}]}>
        {ACTIONS.map((item, i) => (
          <CartActionButton
            key={i}
            value={item}
            disabled={!!max && max === 30}
            onPress={() => {
              if (item === 'MAX') {
                if (max) {
                  changeAmount?.(`${max}`);
                }
              } else {
                const target = Number(amount) + Number(item);
                if (target > max) {
                  globalStore.globalWaringTotal(
                    t('mix-lotto.tip.betMax', {num: max}),
                  );
                  return;
                }
                changeAmount?.(`${target}`);
              }
            }}
          />
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  inputContainer: {
    borderWidth: 1,
    padding: 0,
    borderColor: 'var(--game-line)',
    backgroundColor: 'var(--dark-bg)',
    borderRadius: 8,
    flex: 1,
    marginBottom: 0,
    height: 40,
  },
});

export default React.memo(CartItem);
