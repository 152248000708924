import {StyleProp, View, ViewStyle} from 'react-native';
import Text from '@/components/basic/text';
import theme from '@/style';
import React from 'react';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';

const Tag = ({
  label,
  style,
  backgroundColor,
}: {
  label?: string;
  style?: StyleProp<ViewStyle>;
  backgroundColor?: string;
}) => {
  const {calcActualSize} = useScreenSize();
  return (
    <View
      style={[
        {
          backgroundColor,
          padding: calcActualSize(theme.paddingSize.xxs),
          borderRadius: calcActualSize(theme.paddingSize.xs),
        },
        style,
      ]}>
      <Text color={'white'} fontFamily="fontInterBold">
        {label}
      </Text>
    </View>
  );
};

export default Tag;
