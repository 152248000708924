import {View} from 'react-native';
import Text from '@/components/basic/text';
import React from 'react';
import {INDEX_TO_O, RESULT_INDEX} from '../constant';
import DigitBall from '../component/digit-ball';
import theme from '@/style';
import dayjs from 'dayjs';

export interface HistoryItemProps {
  index?: number;
  drawTime: number;
  roundNo?: string;
  results?: string[];
}

const HistoryItem = ({
  index = 0,
  roundNo = 'No.777',
  results = ['998866', '556677'],
  drawTime,
}: HistoryItemProps) => {
  const backgroundColor = React.useMemo(() => {
    if (index % 2 === 0) {
      return '#262B33';
    } else {
      return 'var(--button-s)';
    }
  }, [index]);

  return (
    <View
      style={[
        theme.flex.row,
        theme.flex.centerByCol,
        theme.flex.between,
        theme.padding.l,
        {backgroundColor},
      ]}>
      <View style={[theme.flex.flex1, theme.margin.rightl]}>
        <Text size="medium" fontWeight="500" color={'var(--T1)'}>
          {roundNo}
        </Text>
        {typeof drawTime === 'string' ? null : (
          <View>
            <Text fontWeight="500">
              {dayjs(drawTime * 1000).format('DD-MM-YYYY')}
            </Text>
            <Text fontWeight="500">
              {dayjs(drawTime * 1000).format('hh:mm A')}
            </Text>
          </View>
        )}
      </View>
      <View style={{gap: theme.paddingSize.xxs}}>
        {results.map((item, _i) => (
          <View style={[theme.flex.row, theme.flex.between]} key={_i}>
            <Text fontFamily="fontInterBold">{INDEX_TO_O[_i + 1]} Prize：</Text>
            <View
              style={[
                theme.flex.row,
                {gap: theme.paddingSize.xxs},
                theme.margin.leftxxs,
              ]}>
              {item
                .split('')
                .slice(0, 6)
                .map((pie, _ii) => (
                  <DigitBall
                    disabled={
                      RESULT_INDEX.slice(-item.split('').slice(0, 6).length)[
                        _ii
                      ] === 'UNUSED'
                    }
                    key={_ii}
                    digit={pie}
                    type={
                      RESULT_INDEX.slice(-item.split('').slice(0, 6).length)[
                        _ii
                      ]
                    }
                  />
                ))}
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};

export default HistoryItem;
