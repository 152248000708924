import {StyleSheet, ScrollView, ViewStyle, StyleProp, View} from 'react-native';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import Text from '@/components/basic/text';
import React from 'react';
import theme from '@/style';
import {PrizeItem} from '../mix-lottery-service';

export interface PrizeTabProps {
  tabs: PrizeItem[];
  onChange?: (id: number) => void;
  selectPrize?: number;
  style?: StyleProp<ViewStyle>;
}

const PrizeTab = ({
  tabs = [
    {
      id: 1,
      name: '1st-prize',
    },
    {
      id: 2,
      name: '2nd-prize',
    },
  ],
  onChange,
  selectPrize = 1,
  style,
}: PrizeTabProps) => {
  return (
    <View>
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={[
          theme.padding.leftl,
          {gap: theme.paddingSize.m},
        ]}
        style={[styles.container, theme.padding.tbs, style]}>
        {tabs.map((item, _i) => (
          <NativeTouchableOpacity
            key={_i}
            onPress={() => onChange?.(item.id)}
            style={[
              theme.padding.tbxs,
              theme.padding.lrl,
              selectPrize === item.id && styles.active,
            ]}>
            <Text
              color={selectPrize === item.id ? 'white' : 'var(--T2-dark)'}
              fontFamily={
                selectPrize === item.id ? 'fontInterBold' : 'fontInter'
              }
              size="medium">
              {item.name}
            </Text>
          </NativeTouchableOpacity>
        ))}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'var(--card)',
    borderBottomColor: 'var(--game-line)',
    borderBottomWidth: 1,
  },
  active: {
    backgroundColor: 'var(--button-s)',
    borderRadius: 40,
  },
});

export default PrizeTab;
