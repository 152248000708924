import {View} from 'react-native';
import Text from '@/components/basic/text';
import React from 'react';
import theme from '@/style';

export interface AnalyzeLabelProps {
  label?: string;
  size?: number;
  backgroundColor?: string;
}

const AnalyzeLabel = ({
  label = '',
  size = 10,
  backgroundColor,
}: AnalyzeLabelProps) => {
  return (
    <View style={[theme.flex.row, theme.flex.centerByCol]}>
      <View
        style={[
          theme.margin.rightxxs,
          {width: size, height: size, backgroundColor},
        ]}
      />
      <Text fontWeight="500" color={'var(--T1)'}>
        {label}
      </Text>
    </View>
  );
};

export default AnalyzeLabel;
