import theme from '@/style';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {View, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import MoreBtn from '@/components/business/order/more-btn';
import ListItem from './list-item';
import {LotteryOrderTicket} from '../mix-lottery-service';

const HEADER = [
  {
    label: 'numbers',
    style: {
      flex: 1,
    },
  },
  {
    label: 'payment',
    style: {
      ...theme.flex.centerByCol,
      flex: 1,
    },
  },
  {
    label: 'result',
    style: {
      flex: 1,
      ...theme.flex.alignEnd,
    },
  },
];

const whenNumToHide = 5;

const TicketList = ({
  ticketList = [],
  isOpen,
}: {
  ticketList?: LotteryOrderTicket[];
  isOpen?: boolean;
}) => {
  const {i18n} = useTranslation();
  const [showMore, setShowMore] = React.useState(false);

  const list = React.useMemo(() => {
    if (ticketList.length <= whenNumToHide) {
      return ticketList;
    } else {
      return showMore ? ticketList : ticketList.slice(0, whenNumToHide);
    }
  }, [showMore, ticketList]);

  return (
    <View style={[theme.padding.lrl]}>
      <View style={[theme.flex.row, styles.header, theme.flex.between]}>
        {HEADER.map((item, _i) => (
          <View key={_i} style={item.style}>
            <Text>{i18n.t('game-page.label.' + item.label).toUpperCase()}</Text>
          </View>
        ))}
      </View>
      {list.map((item, _i) => (
        <ListItem isOpen={isOpen} key={_i} index={_i} {...item} />
      ))}
      {ticketList.length > whenNumToHide && !showMore && (
        <MoreBtn onPress={() => setShowMore(true)} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    backgroundColor: '#3B434F',
    paddingHorizontal: 12,
    paddingVertical: 8,
    gap: 8,
  },
  itemAction: {
    height: 48,
  },
  iconSize: {
    width: 14,
    height: 14,
  },
  ballSize: {
    width: 28,
    height: 28,
    justifyContent: 'center',
    alignItems: 'center',
  },
  number: {
    width: 60,
  },
  time: {
    flex: 1,
    alignItems: 'center',
  },
});

export default TicketList;
