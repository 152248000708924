import theme from '@/style';
import React from 'react';
import {View, StyleSheet} from 'react-native';
import {COLORS_LIGHT, COLORS} from '../constant';
import AnalyzeLabel from './analyze-label';
import Text from '@/components/basic/text';
import Svg, {Circle} from 'react-native-svg';

export interface DigitAnalyzeProps {
  title?: string;
  type: 'A' | 'B' | 'C' | 'D' | 'UNUSED';
  rate?: number;
}

const DigitAnalyze = ({
  title = '',
  type = 'A',
  rate = 0,
}: DigitAnalyzeProps) => {
  const labels = React.useMemo(() => {
    let arr = [] as string[];
    if (title) {
      arr = title.split('/');
    }
    return arr;
  }, [title]);

  return (
    <View style={[styles.container, theme.padding.l]}>
      <Text
        textAlign="center"
        color={'var(--T1)'}
        size="medium"
        fontWeight="500">
        {title}
      </Text>
      <Text
        fontWeight="500"
        style={[theme.position.abs, styles.per]}
        color={'var(--T2-dark)'}
        size="small">
        (%)
      </Text>
      <View
        style={[
          theme.margin.tops,
          theme.flex.row,
          theme.flex.between,
          theme.flex.centerByCol,
        ]}>
        <Svg width={78} height={78} style={[{transform: [{rotate: '-90deg'}]}]}>
          <Circle
            cx={39}
            r={28}
            cy={39}
            fill={'transparent'}
            stroke={COLORS[type]}
            strokeWidth={22}
            strokeDasharray={[176, 176]}
            strokeDashoffset={176 * (1 - rate)}
          />
          <Circle
            cx={39}
            r={28}
            cy={39}
            fill={'transparent'}
            stroke={COLORS_LIGHT[type]}
            strokeWidth={22}
            strokeDasharray={[176, 176]}
            strokeDashoffset={-176 * rate}
          />
        </Svg>
        <Text
          size="small"
          fontWeight="500"
          color={'white'}
          style={[
            theme.position.abs,
            1 - rate > 0.4
              ? {left: 5, top: 30}
              : 1 - rate > 0
              ? {left: 10, top: 10}
              : {},
          ]}>
          {100 - Math.round(rate * 100)}
        </Text>
        <Text
          fontWeight="500"
          size="small"
          color={'white'}
          style={[
            theme.position.abs,
            rate > 0.4
              ? {left: 58, top: 30}
              : rate > 0
              ? {left: 50, top: 10}
              : {},
          ]}>
          {Math.round(rate * 100)}
        </Text>
        <View style={[{gap: theme.paddingSize.s}]}>
          <AnalyzeLabel label={labels[0]} backgroundColor={COLORS[type]} />
          <AnalyzeLabel
            label={labels[1]}
            backgroundColor={COLORS_LIGHT[type]}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 160,
    backgroundColor: 'var(--dark-bg)',
    borderRadius: 8,
  },
  per: {
    top: 8,
    right: 8,
  },
});

export default DigitAnalyze;
