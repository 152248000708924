import React from 'react';
import {
  View,
  ScrollView,
  TouchableOpacity,
  StyleSheet,
  Animated,
  StyleProp,
  ViewStyle,
} from 'react-native';
import Text from '@/components/basic/text';
import theme from '@/style';
import {LotteryMode} from '../mix-lottery-service';

export interface BetTabProps {
  tabList?: {label?: string; key: `${LotteryMode}`}[];
  selectedKey?: string;
  onChange?: (key: `${LotteryMode}`) => void;
  style?: StyleProp<ViewStyle>;
}

const BetTab = ({tabList = [], selectedKey, onChange, style}: BetTabProps) => {
  const [measure, setMeasure] = React.useState<number[]>([]);
  const transAnim = React.useRef(new Animated.Value(0)).current;
  const scrollRef = React.useRef<ScrollView>(null);
  const onMeasure = (i: number, value: number) => {
    measure[i] = value;
    setMeasure([...measure]);
  };

  React.useEffect(() => {
    if (measure.length > 0 && tabList.length === measure.length) {
      const index = tabList.findIndex(item => item.key === selectedKey);
      onTrans(measure[index]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measure, tabList, selectedKey]);

  const onTrans = (v: number) => {
    Animated.timing(transAnim, {
      toValue: v,
      duration: 200,
      useNativeDriver: true,
    }).start();
  };

  return (
    <View style={[styles.container, style]}>
      <ScrollView
        showsHorizontalScrollIndicator={false}
        horizontal
        ref={scrollRef}
        contentContainerStyle={[
          {gap: theme.paddingSize.xxl * 2},
          theme.padding.lrl,
          theme.padding.btms,
        ]}>
        {tabList.map((item, _i) => {
          const isSelected = item.key === selectedKey;
          return (
            <TouchableOpacity
              onPress={() => {
                onChange?.(item.key);
              }}
              key={_i}
              activeOpacity={0.8}
              onLayout={e => {
                const {width, x} = e.nativeEvent.layout;
                const defaultW = 20;
                const target = (width - defaultW) / 2 + x;
                onMeasure(_i, target);
              }}>
              <Text
                color={isSelected ? 'var(--T1)' : theme.fontColor.second}
                size="medium"
                fontWeight={isSelected ? '700' : '500'}>
                {item.label}
              </Text>
            </TouchableOpacity>
          );
        })}
        <Animated.View
          style={[
            styles.square,
            {
              transform: [{translateX: transAnim}],
            },
          ]}
        />
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'var(--button-s)',
    paddingTop: 15,
    paddingBottom: 8,
    borderBottomColor: 'var(--game-line)',
    borderBottomWidth: 1,
  },
  square: {
    width: 20,
    height: 4,
    position: 'absolute',
    left: 0,
    bottom: 0,
    backgroundColor: theme.basicColor.primary,
  },
});

export default BetTab;
